.navbar-modal {
  background: #757F9A;
  background: -webkit-linear-gradient(to right, #D7DDE8, #1c8a5a);
  background: linear-gradient(to right, #D7DDE8, #1c8a5a);
}
.block-effect {
  text-align: center;
  font-size: calc(5px + 3vw);
}

.block-reveal {
  --t: calc(var(--td) + var(--d));
  color: transparent;
  padding: 4px;
  position: relative;
  overflow: hidden;
  animation: revealBlock 0s var(--t) forwards;
}

.block-reveal::after {
  content: "";
  width: 0%;
  height: 100%;
  padding-bottom: 4px;
  position: absolute;
  top: 0;
  left: 0;
  background: var(--bc);
  animation: revealingIn var(--td) var(--d) forwards,
    revealingOut var(--td) var(--t) forwards;
}
.bg-gradient {
  background: #757F9A;
  background: -webkit-linear-gradient(to right, #D7DDE8, #1c8a5a);
  background: linear-gradient(to right, #D7DDE8, #1c8a5a);
}

@keyframes revealBlock {
  100% {
    color: #312a32;
  }
}

@keyframes revealingIn {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

@keyframes revealingOut {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100%);
  }
}
